:focus {
  outline: none;
}

:focus-visible {
  outline-color: var(--tkww-union-essentials-outline-color, var(--tkww-union-color-additional-01extra-400, #0073E6));
  outline-offset: var(--tkww-union-essentials-outline-offset, 2px);
  outline-style: solid;
  outline-width: 3px;
}

html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul {
  margin: 0;
  padding: 0;
}

img,
picture {
  display: block;
  height: auto;
  max-width: 100%;
  width: auto;
}

a,
area,
button,
[role=button],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

input,
textarea,
button,
select {
  color: inherit;
  font-family: inherit;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
}
.sub-nav-container--7b881 {
  background-color: var(--tkww-union-sub-nav-background-color, var(--tkww-union-color-background-dark, var(--tkww-union-color-neutral-black, #000)));
  display: flex;
  height: var(--tkww-union-sub-nav-height, 64px);
  width: 100%;
}

.sub-nav-item-list--acf97 {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  height: inherit;
  justify-content: flex-start;
  margin: 0 auto;
  max-width: 1360px;
  padding-left: 4px;
  width: 100%;
}
.sub-nav-item-list--acf97::before, .sub-nav-item-list--acf97::after {
  content: "";
  display: block;
}
.hero-xl--1d857 {
  letter-spacing: var(--tkww-union-typography-category-hero-xl-letter-spacing, -0.02em);
  font-weight: var(--tkww-union-typography-category-hero-xl-font-weight, var(--tkww-union-typography-extra-bold-font-weight, 800));
  font-family: var(--tkww-union-typography-category-hero-xl-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-hero-xl-font-size, 5.625rem);
  line-height: var(--tkww-union-typography-category-hero-xl-line-height, 1);
}

.hero-lg--f935c {
  letter-spacing: var(--tkww-union-typography-category-hero-lg-letter-spacing, -0.02em);
  font-weight: var(--tkww-union-typography-category-hero-lg-font-weight, var(--tkww-union-typography-extra-bold-font-weight, 800));
  font-family: var(--tkww-union-typography-category-hero-lg-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-hero-lg-font-size, 3.75rem);
  line-height: var(--tkww-union-typography-category-hero-lg-line-height, 1);
}

.hero-md--c3f1a {
  letter-spacing: var(--tkww-union-typography-category-hero-md-letter-spacing, -0.02em);
  font-weight: var(--tkww-union-typography-category-hero-md-font-weight, var(--tkww-union-typography-extra-bold-font-weight, 800));
  font-family: var(--tkww-union-typography-category-hero-md-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-hero-md-font-size, 3rem);
  line-height: var(--tkww-union-typography-category-hero-md-line-height, 1);
}

.hero-sm--d1e56 {
  letter-spacing: var(--tkww-union-typography-category-hero-sm-letter-spacing, -0.02em);
  font-weight: var(--tkww-union-typography-category-hero-sm-font-weight, var(--tkww-union-typography-extra-bold-font-weight, 800));
  font-family: var(--tkww-union-typography-category-hero-sm-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-hero-sm-font-size, 2.125rem);
  line-height: var(--tkww-union-typography-category-hero-sm-line-height, 1);
}

.h1--62ab0 {
  font-weight: var(--tkww-union-typography-category-h1-font-weight, var(--tkww-union-typography-bold-font-weight, 500));
  font-family: var(--tkww-union-typography-category-h1-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-h1-font-size, 1.875rem);
  line-height: var(--tkww-union-typography-category-h1-line-height, 1.2);
}

.h2--b75a9 {
  font-weight: var(--tkww-union-typography-category-h2-font-weight, var(--tkww-union-typography-bold-font-weight, 500));
  font-family: var(--tkww-union-typography-category-h2-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-h2-font-size, 1.75rem);
  line-height: var(--tkww-union-typography-category-h2-line-height, 1.2142857143);
}

.h3--ddfd5 {
  letter-spacing: var(--tkww-union-typography-category-h3-letter-spacing, 0);
  font-weight: var(--tkww-union-typography-category-h3-font-weight, var(--tkww-union-typography-bold-font-weight, 500));
  font-family: var(--tkww-union-typography-category-h3-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-h3-font-size, var(--tkww-union-typography-scale-600-font-size, 1.5rem));
  line-height: var(--tkww-union-typography-category-h3-line-height, 1.1666666667);
}

.h4--224bc {
  letter-spacing: var(--tkww-union-typography-category-h4-letter-spacing, 0);
  font-weight: var(--tkww-union-typography-category-h4-font-weight, var(--tkww-union-typography-bold-font-weight, 500));
  font-family: var(--tkww-union-typography-category-h4-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-h4-font-size, 1.375rem);
  line-height: var(--tkww-union-typography-category-h4-line-height, 1.1818181818);
}

.subhead--150a2 {
  letter-spacing: var(--tkww-union-typography-category-subhead-letter-spacing, 0);
  font-family: var(--tkww-union-typography-category-subhead-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-subhead-font-size, var(--tkww-union-typography-scale-500-font-size, 1.25rem));
  line-height: var(--tkww-union-typography-category-subhead-line-height, var(--tkww-union-typography-scale-500-line-height, 1.4));
}

.editorial--802b2 {
  letter-spacing: var(--tkww-union-typography-category-editorial-letter-spacing, 0);
  font-family: var(--tkww-union-typography-category-editorial-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-editorial-font-size, var(--tkww-union-typography-scale-450-font-size, 1.125rem));
  line-height: var(--tkww-union-typography-category-editorial-line-height, 1.4444444444);
}

.body1--e44d4 {
  letter-spacing: var(--tkww-union-typography-category-body1-letter-spacing, 0);
  font-family: var(--tkww-union-typography-category-body1-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-body1-font-size, var(--tkww-union-typography-scale-300-font-size, 1rem));
  line-height: var(--tkww-union-typography-category-body1-line-height, 1.375);
}

.body2--f71c1 {
  letter-spacing: var(--tkww-union-typography-category-body2-letter-spacing, 0);
  font-family: var(--tkww-union-typography-category-body2-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-body2-font-size, var(--tkww-union-typography-scale-200-font-size, 0.875rem));
  line-height: var(--tkww-union-typography-category-body2-line-height, var(--tkww-union-typography-scale-200-line-height, 1.42857));
}

.buttonL--736ee {
  letter-spacing: var(--tkww-union-typography-category-buttonL-letter-spacing, 0);
  font-weight: var(--tkww-union-typography-category-buttonL-font-weight, var(--tkww-union-typography-bold-font-weight, 500));
  font-family: var(--tkww-union-typography-category-buttonL-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-buttonL-font-size, var(--tkww-union-typography-scale-300-font-size, 1rem));
  line-height: var(--tkww-union-typography-category-buttonL-line-height, var(--tkww-union-typography-scale-300-line-height, 1.5));
}

.buttonM--9f7ab {
  letter-spacing: var(--tkww-union-typography-category-buttonM-letter-spacing, 0);
  font-weight: var(--tkww-union-typography-category-buttonM-font-weight, var(--tkww-union-typography-bold-font-weight, 500));
  font-family: var(--tkww-union-typography-category-buttonM-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-buttonM-font-size, var(--tkww-union-typography-scale-200-font-size, 0.875rem));
  line-height: var(--tkww-union-typography-category-buttonM-line-height, var(--tkww-union-typography-scale-200-line-height, 1.42857));
}

.overline--fd5bf {
  letter-spacing: var(--tkww-union-typography-category-overline-letter-spacing, 2px);
  text-transform: var(--tkww-union-typography-category-overline-text-transform, uppercase);
  font-weight: var(--tkww-union-typography-category-overline-font-weight, var(--tkww-union-typography-bold-font-weight, 500));
  font-family: var(--tkww-union-typography-category-overline-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-overline-font-size, var(--tkww-union-typography-scale-100-font-size, 0.75rem));
  line-height: var(--tkww-union-typography-category-overline-line-height, 1.3333333333);
}

.caption-lg--e66c1 {
  letter-spacing: var(--tkww-union-typography-category-caption-lg-letter-spacing, 0);
  font-weight: var(--tkww-union-typography-category-caption-lg-font-weight, var(--tkww-union-typography-regular-font-weight, 400));
  font-family: var(--tkww-union-typography-category-caption-lg-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-caption-lg-font-size, var(--tkww-union-typography-scale-100-font-size, 0.75rem));
  line-height: var(--tkww-union-typography-category-caption-lg-line-height, 1.3333333333);
}

.caption-sm--e338f {
  letter-spacing: var(--tkww-union-typography-category-caption-sm-letter-spacing, 0);
  font-weight: var(--tkww-union-typography-category-caption-sm-font-weight, var(--tkww-union-typography-regular-font-weight, 400));
  font-family: var(--tkww-union-typography-category-caption-sm-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-caption-sm-font-size, 0.6875rem);
  line-height: var(--tkww-union-typography-category-caption-sm-line-height, 1.4545454545);
}

@media (max-width: 767.98px) {
  .hero-xl--1d857 {
    font-size: var(--tkww-union-typography-category-hero-xl-font-size, 3.75rem);
    line-height: var(--tkww-union-typography-category-hero-xl-line-height, 1);
  }
}
@media (max-width: 767.98px) {
  .hero-lg--f935c {
    font-size: var(--tkww-union-typography-category-hero-lg-font-size, 2.8125rem);
    line-height: var(--tkww-union-typography-category-hero-lg-line-height, 1);
  }
}
@media (max-width: 767.98px) {
  .hero-md--c3f1a {
    font-size: var(--tkww-union-typography-category-hero-md-font-size, 2.25rem);
    line-height: var(--tkww-union-typography-category-hero-md-line-height, 1);
  }
}
@media (max-width: 767.98px) {
  .hero-sm--d1e56 {
    font-size: var(--tkww-union-typography-category-hero-sm-font-size, 1.875rem);
    line-height: var(--tkww-union-typography-category-hero-sm-line-height, 1);
  }
}
@media (max-width: 767.98px) {
  .h1--62ab0 {
    font-size: var(--tkww-union-typography-category-h1-font-size, 1.75rem);
    line-height: var(--tkww-union-typography-category-h1-line-height, 1.2142857143);
  }
}
@media (max-width: 767.98px) {
  .h2--b75a9 {
    font-size: var(--tkww-union-typography-category-h2-font-size, 1.625rem);
    line-height: var(--tkww-union-typography-category-h2-line-height, 1.2307692308);
  }
}
@media (max-width: 767.98px) {
  .h3--ddfd5 {
    font-size: var(--tkww-union-typography-category-h3-font-size, 1.5rem);
    line-height: var(--tkww-union-typography-category-h3-line-height, 1.1666666667);
  }
}
@media (max-width: 767.98px) {
  .h4--224bc {
    font-size: var(--tkww-union-typography-category-h4-font-size, 1.375rem);
    line-height: var(--tkww-union-typography-category-h4-line-height, 1.1818181818);
  }
}
@media (max-width: 767.98px) {
  .subhead--150a2 {
    font-size: var(--tkww-union-typography-category-subhead-font-size, 1.25rem);
    line-height: var(--tkww-union-typography-category-subhead-line-height, 1.4);
  }
}
/* stylelint-disable font-family-no-missing-generic-family-keyword */
.primary--8529c {
  font-family: var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif);
  font-weight: var(--tkww-union-typography-regular-font-weight, 400);
}

.secondary--1b882 {
  font-family: var(--tkww-union-typography-secondary-font-family, union-secondary-font, union-serif, Georgia, "Times New Roman", Times, serif);
  font-weight: var(--tkww-union-typography-regular-font-weight, 400);
}

.primaryBold--1abd6 {
  font-family: var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif);
  font-weight: var(--tkww-union-typography-bold-font-weight, 500);
}

.primaryExtraBold--b783f {
  font-family: var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif);
  font-weight: var(--tkww-union-typography-extra-bold-font-weight, 800);
}
.sr-only--19d91 {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.list-item--07994 {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-left: 28px;
  position: relative;
  text-align: center;
}
.list-item--07994:last-child {
  margin-right: 28px;
}
.list-item--07994:hover, .list-item--07994:active {
  box-shadow: inset 0 -0.25rem var(--tkww-union-sub-nav-underline-color, var(--tkww-union-color-neutral-white, #FFF));
}

.link--46456 {
  color: var(--tkww-union-color-text-on-dark-default, var(--tkww-union-color-neutral-white, #FFF)) !important;
  line-height: 1;
}
.link--46456:hover {
  text-decoration: var(--tkww-union-sub-nav-item-active-text-decoration, none);
}

.active--1ad54 {
  box-shadow: inset 0 -0.25rem var(--tkww-union-sub-nav-underline-color, var(--tkww-union-color-neutral-white, #FFF));
}
.list-item--ba824 {
  margin-left: 24px;
}

.button--59a76 {
  --tkww-union-buttons-primary-alternative-border-color: var(--tkww-union-sub-nav-button-border-color, var(--tkww-union-color-neutral-white, #FFF));
  --tkww-union-buttons-primary-alternative-color: var(--tkww-union-sub-nav-button-color, var(--tkww-union-color-text-on-dark-default, var(--tkww-union-color-neutral-white, #FFF)));
  --tkww-union-buttons-primary-alternative-hover-border-color: var(--tkww-union-sub-nav-button-hover-border-color, var(--tkww-union-color-neutral-300, #CACCD0));
  --tkww-union-buttons-primary-alternative-hover-background-color: var(--tkww-union-sub-nav-button-hover-background-color, transparent);
  --tkww-union-buttons-primary-alternative-hover-color: var(--tkww-union-sub-nav-button-hover-color, var(--tkww-union-color-neutral-300, #CACCD0));
  --tkww-union-buttons-primary-alternative-disabled-border-color: var(--tkww-union-sub-nav-button-disabled-border-color, var(--tkww-union-color-neutral-500, #51545C));
  --tkww-union-buttons-primary-alternative-disabled-color: var(--tkww-union-sub-nav-button-disabled-color, var(--tkww-union-color-text-subtle, var(--tkww-union-color-neutral-500, #51545C)));
  --tkww-union-buttons-primary-alternative-spinner-color: var(--tkww-union-sub-nav-button-spinner-color, var(--tkww-union-color-neutral-white, #FFF));
  --tkww-union-buttons-primary-alternative-pressed-color: var(--tkww-union-sub-nav-button-pressed-color, var(--tkww-union-color-neutral-white, #FFF));
  --tkww-union-buttons-medium-vertical-padding: var(--tkww-union-sub-nav-button-vertical-padding, 6px);
  --tkww-union-typography-category-buttonM-font-size: var(--tkww-union-sub-nav-button-font-size, 14px);
}

.icon--1935d {
  display: inline-block;
  fill: currentColor;
  stroke: currentColor;
  stroke-width: 0;
}

.icon-has-no-size--189a8 {
  height: 1em;
  width: 1em;
}

.size-sm--ad48b {
  height: var(--tkww-union-icons-sm-size, 1rem);
  width: var(--tkww-union-icons-sm-size, 1rem);
}

.size-md--ffa00 {
  height: var(--tkww-union-icons-md-size, 1.5rem);
  width: var(--tkww-union-icons-md-size, 1.5rem);
}

.size-lg--2b900 {
  height: var(--tkww-union-icons-lg-size, 2rem);
  width: var(--tkww-union-icons-lg-size, 2rem);
}
.subNav__linkSection--4c2ec {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  height: 100%;
  width: 100%;
  list-style: none;
}
.subNav__linksContainer--c4e0a {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 1;
}
@media (max-width: 767.98px) {
  .subNav__linksContainer--c4e0a {
    display: none;
  }
}
.subNav__iconButtonSection--4d018 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  flex-grow: 1;
  margin: 0 1.75rem;
  color: var(--tkww-union-color-neutral-white, #FFF);
}
@media (max-width: 767.98px) {
  .subNav__iconButtonSection--4d018 {
    margin: 0 0.5rem;
  }
}
.subNavElementLinkMenu__wrapper--e19a7 {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.subNavElementLinkMenu__container--42705 {
  position: absolute;
  left: calc(-1 * 1rem);
  top: 100%;
  z-index: 15;
}
.subNavElementLinkMenu__content--2d7d5 {
  display: flex;
  flex-direction: column;
  align-items: start;
  min-width: 240px;
  color: black;
  background-color: var(--tkww-union-color-neutral-white, #FFF);
  border-color: var(--tkww-union-color-neutral-300, #CACCD0);
  border-style: solid;
  border-width: 0.0625rem;
  box-shadow: 0 var(--tkww-union-shadow-box-shadow, 2px 4px rgba(31,31,31,0.12));
  margin: auto;
  padding: 0.75rem 1.25rem;
  list-style: none;
}
.subNavElementLinkMenu__item--65898 {
  display: flex;
  flex: 1;
}
.subNavElementLinkMenu__item__link--0e9e5 {
  padding: 0.5rem 0;
  color: var(--tkww-union-color-neutral-500, #51545C);
  font-size: var(--tkww-union-typography-scale-200-font-size, 0.875rem);
  text-decoration: none;
}
.subNavElementLinkMenu__item__link__ww--b6588:hover {
  text-decoration: none;
}
/* !important added to enforce immutable behavior in classes which is a pattern used throughout Union */
.shadow--f55d3 {
  box-shadow: var(--tkww-union-shadow-box-shadow, 0 2px 4px 0 rgba(31,31,31,0.12)) !important;
}
.container--e7bf7 {
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.container--e7bf7 * {
  pointer-events: none;
}

.is-inline--fda67 {
  display: inline-flex;
}

.is-block--c90e8 {
  display: flex;
}

.size-sm--9552e {
  height: var(--tkww-union-icons-interactive-sm-size, 2rem);
  width: var(--tkww-union-icons-interactive-sm-size, 2rem);
}

.size-md--a869b {
  height: var(--tkww-union-icons-interactive-md-size, 2.75rem);
  width: var(--tkww-union-icons-interactive-md-size, 2.75rem);
}

.size-lg--47a16 {
  height: var(--tkww-union-icons-interactive-lg-size, 3rem);
  width: var(--tkww-union-icons-interactive-lg-size, 3rem);
}
.subNavElementLink__link--6d63a {
  height: 100%;
  display: flex;
  font-size: 14px;
  flex-direction: column;
  justify-content: center;
}
.subNavElementLink__menuExpander--61fb4 {
  opacity: 0;
  height: 0;
}
.subNavElementLink__menuExpander--61fb4:focus, .subNavElementLink__menuExpander--61fb4:focus-within {
  opacity: 1;
}
.subNavIconLink__container--3e970 {
  margin: 0.25rem;
  position: relative;
  cursor: pointer;
  color: var(--tkww-union-color-neutral-white, #FFF);
}
.subNavIconLink__text--42ed9 {
  font-size: 12px;
  white-space: nowrap;
}
@media (max-width: 767.98px) {
  .subNavIconLink__text--42ed9 {
    display: none;
  }
}
.subNavIconLink__text__experiment--cb5f9 {
  font-size: 12px;
  white-space: nowrap;
}
@media (max-width: 767.98px) {
  .subNavIconLink__text__experiment--cb5f9 {
    display: none;
  }
}
.subNavIconLink__badge--63194 {
  position: absolute;
  top: 4%;
  right: 4%;
  background-color: var(--tkww-paper-sub-nav-badge-background-color);
  border-radius: 50px;
  min-width: 1rem;
  min-height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.subNavIconLink__icon--860b5 {
  color: var(--tkww-union-color-neutral-white, #FFF);
  padding: 0.25rem;
}
.input--f45af {
  border-radius: var(--tkww-union-fields-select-input-border-radius, 2px);
  height: 3rem;
  padding: 15px calc(0.125rem + 0.5rem) 0;
  transition-property: border-color;
}
.input--f45af:disabled {
  background-color: var(--tkww-union-fields-input-disabled-background-color, var(--tkww-union-color-background-light-contrast, var(--tkww-union-color-neutral-100, #F1F2F4)));
  cursor: not-allowed;
}
.input--f45af[type=button] {
  cursor: pointer;
}

.input-container--d8289 {
  position: relative;
}

.label--abc8d {
  cursor: text;
}
.field-base--ef31a {
  -webkit-appearance: none;
          appearance: none;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  display: block;
  position: relative;
  text-align: left;
  width: 100%;
}
.base--b5452.base--b5452 {
  /* Increase specificity to override blank button styles */
  background-color: var(--tkww-union-fields-base-background-color, var(--tkww-union-color-background-light, var(--tkww-union-color-neutral-white, #FFF)));
}

.base--b5452 {
  caret-color: var(--tkww-union-fields-base-caret-color, var(--tkww-union-color-icon-subtle, var(--tkww-union-color-neutral-500, #51545C)));
  color: var(--tkww-union-fields-base-color, var(--tkww-union-color-text-default, var(--tkww-union-color-neutral-black, #000)));
}
.base--b5452:disabled, .base--b5452.disabled--19829 {
  background-color: var(--tkww-union-fields-base-disabled-background-color, var(--tkww-union-color-background-light-contrast, var(--tkww-union-color-neutral-100, #F1F2F4)));
  color: var(--tkww-union-fields-base-disabled-color, var(--tkww-union-color-text-subtle, var(--tkww-union-color-neutral-500, #51545C)));
  cursor: not-allowed;
}
.ease-out--9d4b8 {
  transition-delay: 0ms;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
}
.input-with-animated-label--f64b4:not([type=button]):focus ~ .animated-label--5c1b9, .input-with-animated-label--f64b4[data-has-value] ~ .animated-label--5c1b9 {
  letter-spacing: var(--tkww-union-typography-category-caption-lg-letter-spacing, 0);
  font-weight: var(--tkww-union-typography-category-caption-lg-font-weight, var(--tkww-union-typography-regular-font-weight, 400));
  font-family: var(--tkww-union-typography-category-caption-lg-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-caption-lg-font-size, var(--tkww-union-typography-scale-100-font-size, 0.75rem));
  line-height: var(--tkww-union-typography-category-caption-lg-line-height, 1.3333333333);
}
.animated-label--5c1b9 {
  padding-left: calc(0.125rem + 0.5rem);
  padding-top: 0.75rem;
  transition-property: font-size, padding;
}

.input-with-animated-label--f64b4[data-has-value] ~ .animated-label--5c1b9 {
  padding-top: 0.25rem;
}
.input-with-animated-label--f64b4:disabled ~ .animated-label--5c1b9 {
  background-color: transparent;
  color: var(--tkww-union-color-text-disabled, var(--tkww-union-color-neutral-300, #CACCD0));
  cursor: not-allowed;
}
.input-with-animated-label--f64b4:not([type=button]):focus ~ .animated-label--5c1b9 {
  padding-top: 0.25rem;
}
.small-label--9ef93 {
  letter-spacing: var(--tkww-union-typography-category-caption-lg-letter-spacing, 0);
  font-weight: var(--tkww-union-typography-category-caption-lg-font-weight, var(--tkww-union-typography-regular-font-weight, 400));
  font-family: var(--tkww-union-typography-category-caption-lg-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-caption-lg-font-size, var(--tkww-union-typography-scale-100-font-size, 0.75rem));
  line-height: var(--tkww-union-typography-category-caption-lg-line-height, 1.3333333333);
}
.label--e1548 {
  color: var(--tkww-union-fields-label-color, var(--tkww-union-color-text-subtle, var(--tkww-union-color-neutral-500, #51545C)));
  left: 1px;
  position: absolute;
  top: 1px;
}

.small-label--9ef93 {
  padding-top: 0.25rem;
}
.container--82e14 {
  position: relative;
}
.sub-text--8b420 {
  max-height: 200px;
  overflow: hidden;
  padding: 6px 0.75rem;
  transition: all 0.1s ease-out 0.1s;
}
/**
Increase specificity of state classes so that they work on button with reset class
*/
.is-invalid--010aa.is-invalid--010aa {
  border-color: var(--tkww-union-fields-input-invalid-border-color, var(--tkww-union-color-border-error, var(--tkww-union-color-status-error-200, #EB1400)));
}

.is-valid--c88ae.is-valid--c88ae {
  border-color: var(--tkww-union-fields-input-valid-border-color, var(--tkww-union-color-border-success, var(--tkww-union-color-status-success-200, #008A05)));
}

.is-neutral--2ce87.is-neutral--2ce87 {
  border-color: var(--tkww-union-fields-input-neutral-border-color, var(--tkww-union-color-border-default, var(--tkww-union-color-neutral-300, #CACCD0)));
}
.is-neutral--2ce87.is-neutral--2ce87:focus {
  border-color: var(--tkww-union-fields-input-focus-border-color, var(--tkww-union-color-border-default, var(--tkww-union-color-neutral-300, #CACCD0)));
}

.is-neutral--2ce87 ~ .next-to-input--5e678 {
  color: var(--tkww-union-fields-input-neutral-subtext-color, var(--tkww-union-color-text-subtle, var(--tkww-union-color-neutral-500, #51545C)));
}

.is-invalid--010aa ~ .next-to-input--5e678 {
  color: var(--tkww-union-fields-input-invalid-subtext-color, var(--tkww-union-color-text-error, var(--tkww-union-color-status-error-200, #EB1400)));
}

.is-valid--c88ae ~ .next-to-input--5e678 {
  color: var(--tkww-union-fields-input-valid-subtext-color, varTextSuccess);
}
.relative-container--7c565 {
  position: relative;
  z-index: 10;
}

.parent-container--73f80 {
  flex-direction: column;
}

.is-block--12d07 {
  display: flex;
}

.is-inline--6ab35 {
  display: inline-flex;
}

.is-closed--7bce3 {
  display: none;
}

.is-open--b2952 {
  display: block;
}

.dropdown-container--ba736 {
  background-color: var(--tkww-union-datepicker-background-color, var(--tkww-union-color-background-light, var(--tkww-union-color-neutral-white, #FFF)));
  border: 1px solid var(--tkww-union-datepicker-border-color, var(--tkww-union-color-border-default, var(--tkww-union-color-neutral-300, #CACCD0)));
  border-radius: var(--tkww-union-datepicker-border-radius, 4px);
  overflow: hidden;
  position: absolute;
  top: 10px;
}

.picker-container--c8190 {
  max-width: calc(280px * var(--tkww-union-datepicker-number-of-months, 1) + 20px * (var(--tkww-union-datepicker-number-of-months, 1) + 1));
  padding-bottom: 18px;
  position: relative;
}

.arrow-up--4fcf7 {
  color: var(--tkww-union-datepicker-navigation-background-color, var(--tkww-union-color-additional-01extra-300, #A1CDF5));
  font-size: 48px;
  left: 6px;
  position: absolute;
  top: -19px;
}

.mobile-overlay--6e7e2 {
  pointer-events: none;
  z-index: 1;
}

.close-button-container--1995c {
  position: absolute;
  right: 7px;
  top: 12px;
  z-index: 1;
}

.close-button--d9674 {
  color: var(--tkww-union-color-icon-subtle, var(--tkww-union-color-neutral-500, #51545C));
}

/* Styles in mobile when the DatePicker is Open */
@media (max-width: 480px) {
  .arrow-up--4fcf7 {
    display: none;
  }

  .dropdown-container--ba736 {
    border-radius: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    top: auto;
    width: 100%;
    z-index: 2;
  }

  .picker-container--c8190 {
    max-width: 100%;
  }

  .grid-cell--74f10 {
    display: grid;
    justify-items: center;
  }
}
.sr-only--19d91 {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.animated--a2915 {
  transition: ease-in 300ms;
  transition-property: background-color;
}

.overlay--252ff {
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  width: 100%;
  z-index: 20;
}
.overlay--252ff.not-visible--b9158, .overlay--252ff.not-enabled--5be17 {
  --overlay-opacity: 0;
}
.overlay--252ff.not-enabled--5be17 {
  pointer-events: none;
}

.color-dark--b0bbc {
  background-color: rgba(31, 31, 31, var(--overlay-opacity, 0.7));
}

.color-light--f6c9a {
  background-color: rgba(255, 255, 255, var(--overlay-opacity, 0.8));
}

.fixed-to-relative--af9e1 {
  position: absolute;
}

.fixed-to-page--c5248 {
  position: fixed;
}
.no-scroll--2ecef {
  overflow: hidden;
}
.root--9a322 {
  --rdp-cell-size: 40px;
  --rdp-caption-font-size: 18px;
  --rdp-accent-color: #0000ff;
  --rdp-background-color: #e7edff;
  --rdp-accent-color-dark: #3003e1;
  --rdp-background-color-dark: #180270;
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused elements */
  --rdp-outline-selected: 3px solid var(--rdp-accent-color);
  /* Outline border for focused _and_ selected elements */
  margin: 1em;
}

/* Hide elements for devices that are not screen readers */
.vhidden--69caf {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  appearance: none;
  position: absolute !important;
  top: 0;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
}

/* Buttons */
.button_reset--1c03f {
  appearance: none;
  position: relative;
  margin: 0;
  padding: 0;
  cursor: default;
  color: inherit;
  background: none;
  font: inherit;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.button_reset--1c03f:focus-visible {
  /* Make sure to reset outline only when :focus-visible is supported */
  outline: none;
}

.button--af2e9 {
  border: 2px solid transparent;
}

.button--af2e9[disabled]:not(.day_selected--34c93) {
  opacity: 0.25;
}

.button--af2e9:not([disabled]) {
  cursor: pointer;
}

.button--af2e9:focus-visible:not([disabled]) {
  color: inherit;
  background-color: var(--rdp-background-color);
  border: var(--rdp-outline);
}

.button--af2e9:hover:not([disabled]):not(.day_selected--34c93) {
  background-color: var(--rdp-background-color);
}

.months--dad59 {
  display: flex;
}

.month--94cf2 {
  margin: 0 1em;
}

.month--94cf2:first-child {
  margin-left: 0;
}

.month--94cf2:last-child {
  margin-right: 0;
}

.table--c3488 {
  margin: 0;
  max-width: calc(var(--rdp-cell-size) * 7);
  border-collapse: collapse;
}

.with_weeknumber--96430 .table--c3488 {
  max-width: calc(var(--rdp-cell-size) * 8);
  border-collapse: collapse;
}

.caption--01599 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  text-align: left;
}

.multiple_months--75a44 .caption--01599 {
  position: relative;
  display: block;
  text-align: center;
}

.caption_dropdowns--ded60 {
  position: relative;
  display: inline-flex;
}

.caption_label--60e79 {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0 0.25em;
  white-space: nowrap;
  color: currentColor;
  border: 0;
  border: 2px solid transparent;
  font-family: inherit;
  font-size: var(--rdp-caption-font-size);
  font-weight: bold;
}

.nav--31ee4 {
  white-space: nowrap;
}

.multiple_months--75a44 .caption_start--f95c7 .nav--31ee4 {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.multiple_months--75a44 .caption_end--18b5d .nav--31ee4 {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.nav_button--447ae {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  padding: 0.25em;
  border-radius: 100%;
}

/* ---------- */
/* Dropdowns  */
/* ---------- */
.dropdown_year--9ca7c,
.dropdown_month--f0b47 {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.dropdown--f2a9f {
  -webkit-appearance: none;
          appearance: none;
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  cursor: inherit;
  opacity: 0;
  border: none;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.dropdown--f2a9f[disabled] {
  opacity: unset;
  color: unset;
}

.dropdown--f2a9f:focus-visible:not([disabled]) + .caption_label--60e79 {
  background-color: var(--rdp-background-color);
  border: var(--rdp-outline);
  border-radius: 6px;
}

.dropdown_icon--ddce2 {
  margin: 0 0 0 5px;
}

.head--2f2c5 {
  border: 0;
}

.head_row--819e2,
.row--309ce {
  height: 100%;
}

.head_cell--6ab2e {
  vertical-align: middle;
  font-size: 0.75em;
  font-weight: 700;
  text-align: center;
  height: 100%;
  height: var(--rdp-cell-size);
  padding: 0;
  text-transform: uppercase;
}

.tbody--27ba0 {
  border: 0;
}

.tfoot--90d12 {
  margin: 0.5em;
}

.cell--6dbd0 {
  width: var(--rdp-cell-size);
  height: 100%;
  height: var(--rdp-cell-size);
  padding: 0;
  text-align: center;
}

.weeknumber--b4f53 {
  font-size: 0.75em;
}

.weeknumber--b4f53,
.day--90c6e {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: var(--rdp-cell-size);
  max-width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  margin: 0;
  border: 2px solid transparent;
  border-radius: 100%;
}

.day_today--539b2:not(.day_outside--d5973) {
  font-weight: bold;
}

.day_selected--34c93,
.day_selected--34c93:focus-visible,
.day_selected--34c93:hover {
  color: white;
  opacity: 1;
  background-color: var(--rdp-accent-color);
}

.day_outside--d5973 {
  opacity: 0.5;
}

.day_selected--34c93:focus-visible {
  /* Since the background is the same use again the outline */
  outline: var(--rdp-outline);
  outline-offset: 2px;
  z-index: 1;
}

.root--9a322:not([dir=rtl]) .day_range_start--35fe7:not(.day_range_end--2fd63) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.root--9a322:not([dir=rtl]) .day_range_end--2fd63:not(.day_range_start--35fe7) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.root--9a322[dir=rtl] .day_range_start--35fe7:not(.day_range_end--2fd63) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.root--9a322[dir=rtl] .day_range_end--2fd63:not(.day_range_start--35fe7) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.day_range_end--2fd63.day_range_start--35fe7 {
  border-radius: 100%;
}

.day_range_middle--401b0 {
  border-radius: 0;
}
.caption_label--91f1c.caption_label--91f1c {
  letter-spacing: var(--tkww-union-typography-category-body1-letter-spacing, 0);
  font-family: var(--tkww-union-typography-category-body1-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-body1-font-size, var(--tkww-union-typography-scale-300-font-size, 1rem));
  line-height: var(--tkww-union-typography-category-body1-line-height, 1.375);
}

.head_cell--5c03d.head_cell--5c03d {
  letter-spacing: var(--tkww-union-typography-category-overline-letter-spacing, 2px);
  text-transform: var(--tkww-union-typography-category-overline-text-transform, uppercase);
  font-weight: var(--tkww-union-typography-category-overline-font-weight, var(--tkww-union-typography-bold-font-weight, 500));
  font-family: var(--tkww-union-typography-category-overline-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-overline-font-size, var(--tkww-union-typography-scale-100-font-size, 0.75rem));
  line-height: var(--tkww-union-typography-category-overline-line-height, 1.3333333333);
}
.root--5b0d3 {
  margin: 0;
  --rdp-accent-color: var(--tkww-union-essentials-outline-color, var(--tkww-union-color-additional-01extra-400, #0073E6));
  --rdp-background-color: transparent;
  --rdp-accent-color-dark: var(--tkww-union-essentials-outline-color, var(--tkww-union-color-additional-01extra-400, #0073E6));
  --rdp-background-color-dark: var(--tkww-union-essentials-outline-color, var(--tkww-union-color-additional-01extra-400, #0073E6));
  --rdp-outline: 3px solid var(--rdp-accent-color);
  /* Outline border for focused elements */
  --rdp-outline-selected: 3px solid var(--rdp-accent-color);
  /* Outline border for focused _and_ selected elements */
}
@media (max-width: 480px) {
  .root--5b0d3 {
    --rdp-cell-size: auto;
  }
}

.months--b4104.months--b4104 {
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
}
@media (max-width: 480px) {
  .months--b4104.months--b4104 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.months--b4104.months--b4104::before {
  background-color: var(--tkww-union-datepicker-navigation-background-color, var(--tkww-union-color-background-light, var(--tkww-union-color-neutral-white, #FFF)));
  content: " ";
  height: 60px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.month--05fa6.month--05fa6 {
  margin: 0;
  margin-right: 20px;
}
@media (max-width: 480px) {
  .month--05fa6.month--05fa6 {
    margin-right: 0;
  }
}

.head--5fe40 {
  position: relative;
}

.caption_label--91f1c.caption_label--91f1c {
  align-items: center;
  border: 3px solid transparent;
  color: var(--tkww-union-datepicker-navigation-color, var(--tkww-union-color-text-default, var(--tkww-union-color-neutral-black, #000)));
  display: inline-flex;
  font-weight: bold;
  margin: 0;
  margin-bottom: 15px;
  margin-left: 0;
  margin-right: 16px;
  margin-top: 21px;
  order: 1;
  padding: 0;
  position: relative;
  white-space: nowrap;
  z-index: 1;
}

.multiple_months--0b983 .caption_label--91f1c {
  margin-top: 18px;
}
.multiple_months--0b983 .nav--9f114.nav--9f114 {
  margin-top: 4px;
}

.nav--9f114 {
  margin-top: 8px;
}

.button_reset--7f9a2.button_reset--7f9a2:focus {
  outline: none;
}
.button_reset--7f9a2.button_reset--7f9a2:focus-visible {
  outline-color: var(--tkww-union-essentials-outline-color, var(--tkww-union-color-additional-01extra-400, #0073E6));
  outline-offset: var(--tkww-union-essentials-outline-offset, 2px);
  outline-style: solid;
  outline-width: 3px;
}

.nav_button--8df53.nav_button--8df53 {
  background-color: transparent;
  border-radius: 0;
  color: var(--tkww-union-datepicker-navigation-color, var(--tkww-union-color-text-default, var(--tkww-union-color-neutral-black, #000)));
  height: 2.75rem;
  width: 2.25rem;
}
.nav_button--8df53.nav_button--8df53:hover {
  background-color: transparent;
  color: var(--tkww-union-datepicker-navigation-color, var(--tkww-union-color-text-default, var(--tkww-union-color-neutral-black, #000)));
}
.nav_button--8df53.nav_button--8df53:focus-visible {
  background-color: transparent;
  color: var(--tkww-union-datepicker-navigation-color, var(--tkww-union-color-text-default, var(--tkww-union-color-neutral-black, #000)));
}

.button--9495b.button--9495b:focus-visible:not([disabled]) {
  background-color: transparent;
  border: none;
}
.button--9495b.button--9495b:hover:not([disabled]):not(.day_selected--1a127) {
  background-color: transparent;
}

.day_outside--2c56a.day_outside--2c56a {
  color: var(--tkww-union-datepicker-day-outside-color, var(--tkww-union-color-link-on-dark-disabled, var(--tkww-union-color-neutral-400, #9699A0)));
  opacity: 1;
}

.day--4ffbd.day--4ffbd {
  border-radius: var(--tkww-union-datepicker-day-border-radius, 50%);
}
.day--4ffbd.day--4ffbd:not(.day_selected--1a127):disabled {
  color: var(--tkww-union-datepicker-day-disabled-color, var(--tkww-union-color-text-disabled, var(--tkww-union-color-neutral-300, #CACCD0)));
  opacity: 1;
}
.day--4ffbd.day--4ffbd:not(.day_selected--1a127):hover:not([disabled]) {
  border: 1px solid var(--tkww-union-datepicker-day-hover-border-color, var(--tkww-union-color-border-dark-default, var(--tkww-union-color-neutral-black, #000)));
}
@media (max-width: 480px) {
  .day--4ffbd.day--4ffbd {
    height: 38px;
    margin: 1.15px 0;
    width: 38px;
  }
}

.day_selected--1a127.day_selected--1a127 {
  background-color: var(--tkww-union-datepicker-day-selected-background-color, var(--tkww-union-color-background-dark, var(--tkww-union-color-neutral-black, #000)));
  color: var(--tkww-union-datepicker-day-selected-color, var(--tkww-union-color-text-on-dark-default, var(--tkww-union-color-neutral-white, #FFF)));
}
.day_selected--1a127.day_selected--1a127:focus-visible:not([disabled]) {
  background-color: var(--tkww-union-datepicker-day-selected-background-color, var(--tkww-union-color-background-dark, var(--tkww-union-color-neutral-black, #000)));
  color: var(--tkww-union-datepicker-day-selected-color, var(--tkww-union-color-text-on-dark-default, var(--tkww-union-color-neutral-white, #FFF)));
}

@media (max-width: 480px) {
  .table--b6520.table--b6520 {
    table-layout: fixed;
    width: 100%;
  }
}

.head_cell--5c03d.head_cell--5c03d {
  color: var(--tkww-union-datepicker-weekdays-color, var(--tkww-union-color-text-subtle, var(--tkww-union-color-neutral-500, #51545C)));
  height: 20px;
  padding-bottom: 12px;
}

.day_today--2866c.day_today--2866c {
  font-weight: normal;
}

.caption--fc229 {
  justify-content: inherit;
}

.cell--fa5b5 {
  padding: 1px 0 0;
}

.row--a1469:first-child .cell--fa5b5,
.row--a1469:last-child .cell--fa5b5 {
  padding: 0 0 1px;
}
.input--f2c8d::placeholder {
  letter-spacing: var(--tkww-union-typography-category-body1-letter-spacing, 0);
  font-family: var(--tkww-union-typography-category-body1-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-body1-font-size, var(--tkww-union-typography-scale-300-font-size, 1rem));
  line-height: var(--tkww-union-typography-category-body1-line-height, 1.375);
}
.container--7de7c {
  display: flex;
  flex-wrap: wrap;
}

.custom-scrollbar--99132::-webkit-scrollbar {
  width: 10px;
}
.custom-scrollbar--99132::-webkit-scrollbar-track {
  background: var(--tkww-union-color-background-light-contrast-2, var(--tkww-union-color-neutral-200, #E4E5E9));
  border-radius: 2px;
  box-shadow: inset -8px 0 var(--tkww-union-color-neutral-white, #FFF);
}
.custom-scrollbar--99132::-webkit-scrollbar-thumb {
  background: var(--tkww-union-color-neutral-500, #51545C);
  border-radius: 2px;
  box-shadow: inset -8px 0 var(--tkww-union-color-neutral-white, #FFF);
}
.custom-scrollbar--99132::-webkit-scrollbar-button:start:decrement, .custom-scrollbar--99132::-webkit-scrollbar-button:end:increment {
  display: block;
  height: 0.25rem;
}

.text-area-reset--464c6 {
  margin: 0;
}

.input--f2c8d {
  color: var(--tkww-union-color-neutral-600, #37393F);
  min-height: 104px;
  padding-bottom: 0.25rem;
  padding-left: calc(0.125rem + 0.5rem);
  padding-right: 0.75rem;
  padding-top: 1.25rem;
  resize: none;
  transition-property: border-color;
}
.input--f2c8d::placeholder {
  color: var(--tkww-union-color-neutral-500, #51545C);
}
.input--f2c8d:disabled {
  background-color: var(--tkww-union-color-background-light-contrast, var(--tkww-union-color-neutral-100, #F1F2F4));
  cursor: not-allowed;
}

.placeholder--c86f1 {
  padding-left: calc(0.125rem + 0.5rem);
  padding-top: 0.5rem;
}

.label--d6d8e {
  background-color: var(--tkww-union-color-neutral-white, #FFF);
  cursor: text;
  padding-left: calc(0.125rem + 0.5rem);
  right: 20px;
  z-index: 1;
}

.length-indicator--afc4d {
  margin-left: auto;
  padding-top: 0.25rem;
  transition: all 0.1s ease-out 0.1s;
}

.length-indicator-valid--1df4c {
  color: var(--tkww-union-fields-select-length-indicator-valid-color, var(--tkww-union-color-text-subtle, var(--tkww-union-color-neutral-500, #51545C)));
}

.length-indicator-invalid--d245b {
  color: var(--tkww-union-fields-select-length-indicator-invalid-color, var(--tkww-union-color-text-error, var(--tkww-union-color-status-error-200, #EB1400)));
}

.label--d6d8e + .input--f2c8d {
  padding-top: 1.25rem;
}
.input--eface {
  text-align: left;
  -webkit-user-select: none;
          user-select: none;
}

.label--2fdef {
  cursor: default;
  pointer-events: none;
}

.caret--502b6 {
  margin-right: 1rem;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0.75rem;
}

.disabled--a9d82 {
}

.disabled--a9d82 ~ .caret--502b6,
.disabled--a9d82 ~ .label--2fdef {
  color: var(--tkww-union-fields-select-disabled-caret-color, var(--tkww-union-color-text-disabled, var(--tkww-union-color-neutral-300, #CACCD0)));
}

.list--9b439 {
  border: 1px solid var(--tkww-union-fields-select-list-border-color, var(--tkww-union-color-border-default, var(--tkww-union-color-neutral-300, #CACCD0)));
  border-radius: var(--tkww-union-fields-select-list-border-radius, 2px);
  /* ul-reset */
  list-style: none;
  margin: 8px 0 0;
  max-height: 275px;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.is-open--93641 .list--9b439 {
  display: block;
}

.is-closed--0af10 .list--9b439 {
  display: none;
}
.disablePointerEvents--117b1 * {
  pointer-events: none;
}
.container--c93cc {
  cursor: default;
  margin-bottom: 1px;
  padding: var(--tkww-union-fields-select-option-padding, 15px 24px);
  position: relative;
}

.container--c93cc:last-child {
  margin-bottom: 0;
}

.is-normal--25c55 {
  color: var(--tkww-union-fields-select-option-color, var(--tkww-union-color-text-default, var(--tkww-union-color-neutral-black, #000)));
}

.checkmark--5007b {
  display: none;
  height: 100%;
  margin-right: 1.25rem;
  position: absolute;
  right: 0;
  top: 0;
}

.is-selected--89bbb {
  background-color: var(--tkww-union-fields-select-option-selected-background-color, var(--tkww-union-color-background-active, var(--tkww-union-color-additional-04extra-100, #FFF5ED)));
  color: var(--tkww-union-fields-select-option-selected-color, var(--tkww-union-color-text-default, var(--tkww-union-color-neutral-black, #000)));
}
.is-selected--89bbb .checkmark--5007b {
  align-items: center;
  display: flex;
  justify-content: center;
}

.is-active--0a768,
.is-active--0a768.is-selected--89bbb {
  background-color: var(--tkww-union-fields-select-option-selected-background-color, var(--tkww-union-color-background-active, var(--tkww-union-color-additional-04extra-100, #FFF5ED)));
  color: var(--tkww-union-fields-select-option-selected-color, var(--tkww-union-color-text-default, var(--tkww-union-color-neutral-black, #000)));
  font-weight: 700;
}

.container--c93cc:hover {
  background-color: var(--tkww-union-fields-select-option-hover-background-color, var(--tkww-union-color-background-active, var(--tkww-union-color-additional-04extra-100, #FFF5ED)));
  color: var(--tkww-union-fields-select-option-selected-color, var(--tkww-union-color-text-default, var(--tkww-union-color-neutral-black, #000)));
  text-decoration: underline;
}
.container--3bc0c {
  position: relative;
}

.list-container--1cc0c {
  background-color: var(--tkww-union-color-background-light, var(--tkww-union-color-neutral-white, #FFF));
  border: 1px solid var(--tkww-union-color-border-default, var(--tkww-union-color-neutral-300, #CACCD0));
  border-radius: var(--tkww-union-typeahead-suggestion-border-radius, 2px);
  left: 0;
  margin: 8px 0 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1;
}

.list--e6ac4 {
  list-style: none;
  margin: 0;
  max-height: 400px;
  overflow-y: auto;
  padding: 0;
}

.item-base--28e4a {
  display: block;
  padding: var(--tkww-union-typeahead-suggestion-item-padding, 0.625rem);
  position: relative;
  transition: background-color 0.3s ease;
}
.item-base--28e4a:hover {
  background-color: var(--tkww-union-fields-typeahead-option-hover-background-color, var(--tkww-union-color-background-active, var(--tkww-union-color-additional-04extra-100, #FFF5ED)));
  color: var(--tkww-union-fields-typeahead-option-hover-color, var(--tkww-union-color-text-default, var(--tkww-union-color-neutral-black, #000)));
  cursor: pointer;
  text-decoration: underline;
}

.item--f8e1f {
  color: var(--tkww-union-fields-typeahead-option-color, var(--tkww-union-color-text-default, var(--tkww-union-color-neutral-black, #000)));
}

.item-is-disabled--67406 {
  color: var(--tkww-union-fields-typeahead-option-color, var(--tkww-union-color-text-subtle, var(--tkww-union-color-neutral-500, #51545C)));
  pointer-events: none;
}

.item-is-active--09c75 {
  background-color: var(--tkww-union-color-background-active, var(--tkww-union-color-additional-04extra-100, #FFF5ED));
  color: var(--tkww-union-color-neutral-600, #37393F);
}
.searchBar--146cb {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}
.searchBar__container--cb1fb {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  position: relative;
}
.searchBar__inputContainer--1fd8a {
  display: flex;
  flex-grow: 2;
}

.searchField__label--2e23d {
  display: none;
}

.clearSearchButton__wrapper--a5121 {
  display: flex;
  position: absolute;
  right: 0%;
  padding: 16px;
  color: var(--tkww-union-color-neutral-500, #51545C);
  cursor: pointer;
}

.searchBar__inputContainer--1fd8a input[type=search] {
  border: none;
  border-radius: 0.125rem 0 0 0.125rem;
  height: 2.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.75rem;
  padding-top: 0.75rem;
  height: 2.75rem;
}
.searchBar__inputContainer--1fd8a input[type=search]::-webkit-search-cancel-button, .searchBar__inputContainer--1fd8a input[type=search]::-webkit-search-decoration, .searchBar__inputContainer--1fd8a input[type=search]::-webkit-search-results-button, .searchBar__inputContainer--1fd8a input[type=search]::-webkit-search-results-decoration {
  display: none;
}
@media (max-width: 767.98px) {
  .searchBar__inputContainer--1fd8a input[type=search] {
    font-size: var(--tkww-union-typography-scale-100-font-size, 0.75rem);
  }
}

.searchBarButton--47453 {
  height: 2.75rem;
  align-items: center;
  border-radius: 0 0.125rem 0.125rem 0 !important;
  display: flex !important;
  justify-content: center;
  margin: 0;
  max-width: 2.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
@media (max-width: 767.98px) {
  .searchBarButton--47453 {
    padding: 0 !important;
    min-width: 2.75rem;
  }
}
.searchBarButton__animated__ww--58c20 {
  background-color: var(--tkww-union-buttons-primary-background-color);
}
.searchBarButton__label--ed7ec {
  font-size: var(--tkww-union-typography-scale-300-font-size, 1rem);
  margin-left: 0.5rem;
}
@media (max-width: 767.98px) {
  .searchBarButton__label--ed7ec {
    display: none;
  }
}
.subNavIconButton__container--ae104 {
  padding: 0.25rem;
}
@media (max-width: 767.98px) {
  .subNavIconButton__closeIcon--27e77 {
    display: none;
  }
}
.subNavButton--58eeb {
  font-size: 12px !important;
  background-color: transparent !important;
}
.subNavButton__ww--c30ea {
  border-radius: 0.5rem !important;
}
@font-face {
  font-family: ProximaNova;
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url("https://www.weddingwire.com/assets/fonts/ProximaNova-regular.woff2") format("woff2"), url("https://www.weddingwire.com/assets/fonts/ProximaNova-regular.woff") format("woff"), url("https://www.weddingwire.com/assets/fonts/ProximaNova-regular.ttf") format("truetype");
}
@font-face {
  font-family: ProximaNova;
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src: url("https://www.weddingwire.com/assets/fonts/ProximaNova-semibold.woff2") format("woff2"), url("https://www.weddingwire.com/assets/fonts/ProximaNova-semibold.woff") format("woff"), url("https://www.weddingwire.com/assets/fonts/ProximaNova-semibold.ttf") format("truetype");
}
@font-face {
  font-family: "ProximaNova Condensed";
  font-style: normal;
  font-weight: 800;
  font-display: fallback;
  src: url("https://www.weddingwire.com/assets/fonts/proximanovacond-extrabold.woff2") format("woff2"), url("https://www.weddingwire.com/assets/fonts/proximanovacond-extrabold.woff") format("woff"), url("https://www.weddingwire.com/assets/fonts/proximanovacond-extrabold.ttf") format("truetype");
}
.subNav--18157 {
  /* Component Colors */
  --tkww-paper-sub-nav-badge-background-color: #f76c6f;
  /* Color */
  --tkww-union-color-primary-400: #17abb1;
  --tkww-union-color-primary-500: #088f95;
  --varNeutral300: #17abb1;
  --tkww-union-sub-nav-background-color: var(--tkww-union-color-primary-500);
  /* Button Colors */
  --tkww-union-buttons-primary-border-color: var(--varNeutral300);
  --tkww-union-buttons-primary-background-color: var(--varNeutral300);
  --tkww-union-buttons-primary-disabled-border-color: var(--varNeutral300);
  --tkww-union-buttons-primary-disabled-background-color: var(--varNeutral300);
  --tkww-union-buttons-primary-hover-border-color: var(--tkww-union-color-primary-400);
  --tkww-union-buttons-primary-hover-background-color: var(--tkww-union-color-primary-400);
  /* Typography - Font */
  --tkww-union-typography-primary-font-family: ProximaNova, helvetica, arial, sans-serif;
  --tkww-union-typography-secondary-font-family: "ProximaNova Condensed", Helvetica, Arial, sans-serif;
}

body {
  -webkit-font-smoothing: inherit;
}
.subNav--baafb {
  /* Component Colors */
  --tkww-paper-sub-nav-badge-background-color: var(--tkww-union-color-primary-400);
  /* Color */
  --tkww-union-sub-nav-background-color: var(--tkww-union-color-neutral-black);
}
.zendeskLauncher--12f31 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: var(--varSpUp2);
  background-color: var(--varPrimary300);
  border-radius: 50%;
  z-index: 12;
  width: 56px;
  height: 56px;
}
.zendeskLauncher--12f31.hide--bc9ea {
  display: none;
}

.iconChatContainer--97da0 {
  position: relative;
  margin: var(--varSpDown2);
  width: var(--varSpUp4);
  height: var(--varSpUp4);
}
.iconChatContainer--97da0 svg.chatIcon--8ba52 {
  fill: var(--varPrimary300);
  stroke: var(--varNeutralWhite);
  stroke-width: 2px;
}
.iconChatContainer--97da0 .messageCount--6e655 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 20px;
  top: var(--varSpDown5);
  padding: var(--varSpDown5);
  background-color: var(--varNeutralBlack);
  border: 3px solid var(--varPrimary300);
  border-radius: 50px;
  color: var(--varNeutralWhite);
  font-size: var(--varFontSize100);
  font-weight: var(--varBoldFontWeight);
  min-width: 20px;
  height: 20px;
}
.tab--b64d1 :focus {
  outline-color: var(--tkww-union-essentials-outline-color, var(--tkww-union-color-additional-01extra-400, #0073E6));
  outline-offset: var(--tkww-union-essentials-outline-offset, 2px);
  outline-style: solid;
  outline-width: 3px;
}

.no-tab--f8995 :focus {
  outline: none;
}

.container--a0c7d {
  background: var(--tkww-union-footer-nav-background-color, var(--tkww-union-color-background-dark, var(--tkww-union-color-neutral-black, #000)));
  color: var(--tkww-union-footer-nav-heading-color, var(--tkww-union-color-text-on-dark-default, var(--tkww-union-color-neutral-white, #FFF)));
  padding-top: 2rem;
}
.container--a0c7d ul li {
  list-style: none;
}

.contentWrapper--8dffb {
  max-width: 1360px;
  width: 100%;
}
@media (max-width: 1023.98px) {
  .contentWrapper--8dffb {
    padding: 0 1.5rem;
  }
}
@media (min-width: 1024px) {
  .contentWrapper--8dffb {
    padding: 0 2rem;
  }
}

.logoColumn--c5bb6 {
  padding-bottom: 2.5rem;
}

.footer--dc097 {
  border-top: 0.5px solid #979797;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 24px;
  padding-top: 24px;
  width: 100%;
}
@media (max-width: 1023.98px) {
  .footer--dc097 {
    align-items: flex-start;
    flex-direction: column;
  }
}
@media (min-width: 1024px) {
  .footer--dc097 {
    align-items: center;
  }
}

.footerLink--9c279 {
  color: var(--tkww-union-footer-nav-link-color, var(--tkww-union-color-text-on-dark-subtle, var(--tkww-union-color-neutral-300, #CACCD0)));
}
.footerLink--9c279:hover {
  color: var(--tkww-union-footer-nav-link-color, var(--tkww-union-color-text-on-dark-subtle, var(--tkww-union-color-neutral-300, #CACCD0)));
}
@media (max-width: 1023.98px) {
  .footerLink--9c279 {
    display: inherit;
    padding-bottom: 20px;
  }
}
@media (min-width: 1024px) {
  .footerLink--9c279 {
    margin-right: 1.5rem;
    padding: 12px 0;
  }
}

.madeWith--3e698 {
  color: var(--tkww-union-footer-nav-heart-text-color, var(--tkww-union-color-text-on-dark-default, var(--tkww-union-color-neutral-white, #FFF)));
  font-size: var(--tkww-union-typography-scale-200-font-size, 0.875rem);
  line-height: 20px;
  margin-top: var(--tkww-union-footer-nav-madeWith-margin-top, -7px);
}

.heartIcon--d55b9 {
  color: var(--tkww-union-footer-nav-heart-color, var(--tkww-union-color-brand-primary, var(--tkww-union-color-primary-300, #FF44CB)));
  display: inline-block;
  height: 20px;
  margin: auto 10px;
  padding-top: 3px;
  vertical-align: middle;
}

@media (min-width: 1024px) {
  .section--7338d {
    margin-right: 36px;
    min-width: fit-content;
  }
}

.sectionsWrapper--21dc6 {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1023.98px) {
  .sectionsWrapper--21dc6 {
    flex-direction: column;
  }
}

.appDownload--9ec85 {
  max-width: 309px;
}
@media (max-width: 1023.98px) {
  .appDownload--9ec85 {
    padding-bottom: 72px;
    padding-top: 52px;
  }
}
@media (min-width: 1024px) {
  .appDownload--9ec85 {
    margin-left: auto;
  }
}

.storeBadgeContainer--d0eee {
  display: flex;
  margin-bottom: 64px;
}

.storeBadge--403ba {
  height: 2.5rem;
  margin-top: 0.75rem;
  width: 139px;
}
.storeBadge--403ba svg {
  width: 100%;
}

.storeBadge--403ba * {
  /* Allow analytics tracking on store badges */
  pointer-events: none;
}

.areYouVendorHeader--00f0b {
  margin-bottom: 10px;
}

.areYouVendorLink--e8a43 {
  color: var(--tkww-union-footer-nav-sidebar-color, var(--tkww-union-color-text-on-dark-default, var(--tkww-union-color-neutral-white, #FFF)));
  display: inline-block;
  margin-top: 12px;
}
.areYouVendorLink--e8a43:hover {
  color: var(--tkww-union-footer-nav-sidebar-color, var(--tkww-union-color-text-on-dark-default, var(--tkww-union-color-neutral-white, #FFF)));
  text-decoration: underline;
}
.areYouVendorLink--e8a43 svg {
  position: relative;
  top: 3px;
}

@media (min-width: 1024px) {
  .mediumRow--146d7 {
    display: flex;
    min-width: 360px;
    padding-right: 3.5rem;
  }
}

@media (min-width: 1024px) {
  .rowColumns--08b34 {
    margin-right: inherit;
    min-width: inherit;
  }
}
.sectionLabel--f5055.sectionLabel--f5055 {
  letter-spacing: var(--tkww-union-typography-category-overline-letter-spacing, 2px);
  text-transform: var(--tkww-union-typography-category-overline-text-transform, uppercase);
  font-weight: var(--tkww-union-typography-category-overline-font-weight, var(--tkww-union-typography-bold-font-weight, 500));
  font-family: var(--tkww-union-typography-category-overline-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-overline-font-size, var(--tkww-union-typography-scale-100-font-size, 0.75rem));
  line-height: var(--tkww-union-typography-category-overline-line-height, 1.3333333333);
}
.sectionLabel--f5055.sectionLabel--f5055 {
  padding-top: 0.25rem;
  width: 100%;
}

.mobileSectionLabel--ebfb2 {
  align-items: flex-end;
  color: var(--tkww-union-color-text-on-dark-default, var(--tkww-union-color-neutral-white, #FFF));
  display: flex;
  justify-content: space-between;
}
@media (min-width: 1024px) {
  .mobileSectionLabel--ebfb2 {
    display: none;
  }
}

.sectionLabel--f5055.mobileSectionLabel--ebfb2 {
  border-bottom: 0.5px solid #979797;
  padding: 1rem 0;
}

.mobileSectionLabel--ebfb2 * {
  /* To allow tracking when clicking on caret icon */
  pointer-events: none;
}

.desktopSectionLabel--cdb2a {
}
@media (max-width: 1023.98px) {
  .desktopSectionLabel--cdb2a {
    display: none;
  }
}

.sectionItemsList--dc5ac {
  padding-top: 1.5rem;
}

@media (min-width: 1024px) {
  .sectionItemsWrapper--11345 {
    margin-bottom: 2rem;
  }
}
@media (max-width: 1023.98px) {
  .sectionItemsWrapper--11345 {
    max-height: 100vh;
    overflow: hidden;
    transition-duration: 300ms;
    transition-property: max-height, visibility;
  }
}

@media (max-width: 1023.98px) {
  .hidden--7c33d {
    max-height: 0;
    visibility: hidden;
  }
}

.productsSection--4be71 {
}

.companySection--cdfbf {
}
@media (min-width: 1024px) {
  .companySection--cdfbf {
    flex: 1 1 128px;
    max-width: 194px;
    min-width: 128px;
  }
}

.sectionGroupRow--cd40f {
  flex: 1;
}
@media (min-width: 768px) {
  .businessLinksContainer--f4bec {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.businessItemBase--7a8f7 {
}

@media (max-width: 767.98px) {
  .businessItemBase--7a8f7.businessItemBase--7a8f7 {
    padding-bottom: 20px;
  }
}
@media (min-width: 768px) {
  .businessItemBase--7a8f7.businessItemBase--7a8f7 {
    margin-right: 1.5rem;
    padding: 12px 0;
  }
}

.copyrightItem--ea931 {
}

.copyrightItem--ea931.copyrightItem--ea931 {
  color: var(--tkww-union-footer-nav-copyright-text-color, var(--tkww-union-color-text-on-dark-default, var(--tkww-union-color-neutral-white, #FFF)));
}
.copyrightItem--ea931.copyrightItem--ea931:hover {
  color: var(--tkww-union-footer-nav-copyright-text-color, var(--tkww-union-color-text-on-dark-default, var(--tkww-union-color-neutral-white, #FFF)));
}
.sectionItemBase--739d4 {
  color: var(--tkww-union-footer-nav-link-color, var(--tkww-union-color-text-on-dark-subtle, var(--tkww-union-color-neutral-300, #CACCD0)));
  display: block;
}
.sectionItemBase--739d4:hover {
  color: var(--tkww-union-footer-nav-link-color, var(--tkww-union-color-text-on-dark-subtle, var(--tkww-union-color-neutral-300, #CACCD0)));
}

a.sectionItemBase--739d4:hover {
  text-decoration: underline;
}

.sectionItem--a25a6 {
  padding-bottom: 0.75rem;
}
.logo--68272 {
  --tkww-union-typography-category-hero-font-size: var(--tkww-union-footer-nav-logo-size, 2.6rem);
}

.logo--68272 * {
  /* Allow analytics tracking on logo */
  pointer-events: none;
}
.socialIcon--7747b {
  color: var(--tkww-union-color-icon-on-dark-default, var(--tkww-union-color-neutral-white, #FFF));
}

.socialIcon--7747b:hover {
  color: var(--tkww-union-footer-nav-social-icon-hover-color, var(--tkww-union-color-neutral-200, #E4E5E9));
}
.socialIconsSection--24135 {
  display: flex;
  margin-left: -10px;
}

ul.socialIconsSection--24135 li:last-child {
  margin-right: -10px;
}
.cookieSettingsButton--9b52d {
  background-color: inherit;
  border: inherit;
  cursor: pointer;
}
.cookieSettingsButton--9b52d:hover {
  text-decoration: underline;
}
.cookieSettingsButton--9b52d:disabled {
  display: none;
}
.button--5b2f7 {
  background: none;
  border: 0;
  cursor: pointer;
  margin: 0;
  padding: 0;
}
.modal-container--a1f1e {
  --union-modal-z-index: calc(var(--union-modal-level, 1) * 20);
}
.left-nav-container--90c77 {
  height: 100%;
}

.left-nav--83811 {
  overflow-y: auto;
  position: sticky;
  top: 0;
}
@media (max-height: 548px) {
  .left-nav--83811 {
    height: 100vh;
  }
}

.skip-to-content--d3b27 {
  position: absolute;
  top: -48px;
}
.skip-to-content--d3b27:focus {
  display: inline-block;
  margin: 12px auto 12px 32px;
  position: static;
}
.skip-to-content--d3b27:focus::after {
  background-color: var(--tkww-union-color-background-light-contrast, var(--tkww-union-color-neutral-100, #F1F2F4));
  content: "";
  height: 48px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: -1;
}
.snackbar-wrapper--fa618 {
  align-items: center;
  bottom: 1.75rem;
  display: flex;
  justify-content: center;
  left: 0;
  pointer-events: none;
  right: 0;
  z-index: 9999;
}
@media (max-width: 479.98px) {
  .snackbar-wrapper--fa618 {
    bottom: 1.375rem;
  }
}

.fixed-to-relative--06c35 {
  position: absolute;
}

.fixed-to-page--46c34 {
  position: fixed;
}

.close-button-container--9e9e2 {
  padding-right: 6px;
}

.container--75aff {
  align-items: center;
  background-color: var(--tkww-union-alerts-snackbar-background-color, var(--tkww-union-color-background-dark, var(--tkww-union-color-neutral-black, #000)));
  border-radius: 0.25rem;
  color: var(--tkww-union-alerts-snackbar-color, var(--tkww-union-color-text-on-dark-default, var(--tkww-union-color-neutral-white, #FFF)));
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  max-width: 25rem;
  min-width: 15.5rem;
  pointer-events: auto;
  text-align: left;
  transition: transform 0.5s ease;
}
@media (max-width: 479.98px) {
  .container--75aff {
    margin: 0 0.75rem;
    min-width: calc(100% - 24px);
  }
}

.container--75aff a {
  color: var(--tkww-union-alerts-snackbar-color, var(--tkww-union-color-text-on-dark-default, var(--tkww-union-color-neutral-white, #FFF)));
  text-decoration: underline;
}

.children--770c6 {
  margin-right: 18px;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-top: 1rem;
}

.is-visible--22f1e {
  transform: translateY(0%);
}

.is-not-visible--ec2ba {
  transform: translateY(calc(100% + 28px));
}
@media (max-width: 479.98px) {
  .is-not-visible--ec2ba {
    transform: translateY(calc(100% + 1.375rem));
  }
}
.container--eb861 {
  border-radius: 0.25rem;
  color: var(--tkww-union-alerts-color, var(--tkww-union-color-text-default, var(--tkww-union-color-neutral-black, #000)));
  display: flex;
  padding: 16px;
  text-align: left;
  transition: all 0.25s ease;
  transition-property: height, padding-top, padding-bottom, opacity;
}

.children--c06a3 a {
  color: var(--tkww-union-alerts-link-color, var(--tkww-union-color-link-dark-on-light, var(--tkww-union-color-neutral-black, #000)));
  text-decoration: underline;
}

.alert-icon--c2d73 {
  margin-right: 12px;
  min-width: 1.5rem;
}

.icon-error--1926b {
  color: var(--tkww-union-alerts-error-icon-color, var(--tkww-union-color-status-error-200, #EB1400));
}

.icon-info--86642 {
  color: var(--tkww-union-alerts-info-icon-color, var(--tkww-union-color-status-info-300, #114394));
}

.icon-success--6c275 {
  color: var(--tkww-union-alerts-success-icon-color, var(--tkww-union-color-status-success-200, #008A05));
}

.icon-warning--d5bb4 {
  color: var(--tkww-union-alerts-warning-icon-color, var(--tkww-union-color-status-warning-200, #C25400));
}

.error--357a5 {
  background-color: var(--tkww-union-alerts-error-background-color, var(--tkww-union-color-background-error, var(--tkww-union-color-status-error-100, #FFECE4)));
}

.info--e7973 {
  background-color: var(--tkww-union-alerts-info-background-color, var(--tkww-union-color-status-info-100, #F4F9FF));
}

.success--c2dba {
  background-color: var(--tkww-union-alerts-success-background-color, var(--tkww-union-color-background-success, var(--tkww-union-color-status-success-100, #E2FFEE)));
}

.warning--57ee7 {
  background-color: var(--tkww-union-alerts-warning-background-color, var(--tkww-union-color-background-warning, var(--tkww-union-color-status-warning-100, #FFF3DD)));
}

.is-visible--e09ee {
  height: auto;
  opacity: 1;
  padding: 16px;
}

.is-not-visible--6bd1f {
  height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
}

.text-container--aa77c {
  display: flex;
  flex-direction: column;
}

.alert-cta-container--396bd {
  margin-top: 12px;
  --tkww-union-buttons-primary-alternative-border-color: var(--tkww-union-alerts-button-border-color);
  --tkww-union-buttons-primary-alternative-background-color: var(--tkww-union-alerts-button-background-color);
  --tkww-union-buttons-primary-alternative-color: var(--tkww-union-alerts-button-color);
  --tkww-union-buttons-primary-alternative-hover-border-color: var(--tkww-union-alerts-button-hover-border-color);
  --tkww-union-buttons-primary-alternative-hover-background-color: var(--tkww-union-alerts-button-hover-background-color);
  --tkww-union-buttons-primary-alternative-hover-color: var(--tkww-union-alerts-button-hover-color);
  --tkww-union-buttons-primary-alternative-disabled-border-color: var(--tkww-union-alerts-button-disabled-border-color);
  --tkww-union-buttons-primary-alternative-disabled-background-color: var(--tkww-union-alerts-button-disabled-background-color);
  --tkww-union-buttons-primary-alternative-disabled-color: var(--tkww-union-alerts-button-disabled-border);
  --tkww-union-buttons-primary-alternative-spinner-color: var(--tkww-union-alerts-button-spinner-color);
  --tkww-union-buttons-primary-alternative-pressed-color: var(--tkww-union-alerts-button-pressed-color);
  --tkww-union-buttons-primary-alternative-pressed-opacity: var(--tkww-union-alerts-button-pressed-opacity);
}

.close-button-container--dd36d {
  margin-left: auto;
}

.close-button--ece31 {
}

.close-button--ece31.close-button--ece31 {
  color: var(--tkww-union-alerts-close-icon-color, var(--tkww-union-color-icon-subtle, var(--tkww-union-color-neutral-500, #51545C)));
  margin: -10px;
}

.alert-headline--57021 {
  color: var(--tkww-union-alerts-headline-color, var(--tkww-union-color-text-default, var(--tkww-union-color-neutral-black, #000)));
  font-weight: bold;
}
